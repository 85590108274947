* {
    font-family: PT Sans;
}

h1 {
    font-size: 36px;
    font-style: normal;
    font-weight: 700;
    line-height: 45px;
    letter-spacing: 0em;
    text-align: center;
    color: rgba(140, 140, 140, 1);
}

h2 {
    font-family: PT Sans;
    font-size: 36px;
    font-style: normal;
    font-weight: 400;
    line-height: 45px;
    letter-spacing: 0em;
    text-align: center;
    color: rgba(189, 109, 41, 1);
    max-width: 70%;
}

ol {
    max-width: 70%;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 23px;
    letter-spacing: 0em;
    text-align: left;
    color: rgba(189, 109, 41, 1);
}

p {
    color: rgba(189, 109, 41, 1);
}

@font-face {
    font-family: "PT Sans";
    src: url("../fonts/PTSans-Regular.ttf") format("truetype");
    font-weight: normal;
    font-style: normal;
}